const path = require('path');
const jssConfig = require('./src/temp/config');
const plugins = require('./src/temp/next-config-plugins') || {};
const tldts = require('tldts');

const languageList = process.env.NEXT_PUBLIC_LANGUAGE_LIST?.split(',') || [];
const defaultLanguage =
  languageList.find((lang) => lang === process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE) || '';

const globalImports = `@import "@hkexpressairwayslimited/ui/build/styles/global";`;

const selfDomain = tldts.getDomain(process.env.PUBLIC_URL)
  ? `*.${tldts.getDomain(process.env.PUBLIC_URL)}`
  : '*.hkexpress.com';

const additionalData = (content) => {
  return `${globalImports}\n${content}`;
};

/**
 * @type {import('next').NextConfig}
 */
const nextConfig = {
  // Allow specifying a distinct distDir when concurrently running app in a container
  distDir: process.env.NEXTJS_DIST_DIR || '.next',

  i18n: {
    // These are all the locales you want to support in your application.
    // These should generally match (or at least be a subset of) those in Sitecore.
    locales: languageList,
    // This is the locale that will be used when visiting a non-locale
    // prefixed path e.g. `/styleguide`.
    defaultLocale: defaultLanguage,
    localeDetection: false,
  },

  // Enable React Strict Mode
  reactStrictMode: process.env.NODE_ENV === 'development',

  // use this configuration to ensure that only images from the whitelisted domains
  // can be served from the Next.js Image Optimization API
  // see https://nextjs.org/docs/app/api-reference/components/image#remotepatterns
  images: {
    remotePatterns: [
      {
        hostname: selfDomain,
      },
      {
        protocol: 'https',
        hostname: 'edge*.**',
        port: '',
      },
      {
        protocol: 'https',
        hostname: 'feaas*.blob.core.windows.net',
        port: '',
      },
      {
        protocol: 'https',
        hostname: new URL(jssConfig.sitecoreApiHost).hostname,
        port: '',
      },
    ],
    unoptimized: !!process.env.EXPORT_MODE,
    dangerouslyAllowSVG: true,
    contentDispositionType: 'attachment',
    contentSecurityPolicy: "default-src 'self'; script-src 'none'; sandbox;",
    minimumCacheTTL: 60 * 15,
  },

  async rewrites() {
    // When in connected mode we want to proxy Sitecore paths off to Sitecore
    return [
      // API endpoints
      {
        source: '/sitecore/api/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/api/:path*`,
      },
      // media items
      {
        source: '/source/:path*',
        destination: `${jssConfig.sitecoreApiHost}/-/media/Project/${jssConfig.sitecoreSiteName}/${jssConfig.sitecoreSiteName}/data/media/:path*`,
      },
      {
        source: '/-/:path*',
        destination: `${jssConfig.sitecoreApiHost}/-/:path*`,
      },
      // healthz check
      {
        source: '/healthz',
        destination: '/api/healthz',
      },
      // rewrite for Sitecore service pages
      {
        source: '/sitecore/service/:path*',
        destination: `${jssConfig.sitecoreApiHost}/sitecore/service/:path*`,
      },
    ];
  },

  sassOptions: {
    includePaths: [path.join(__dirname, '../../node_modules')], // the global folder is inside assets/scss
    additionalData,
  },

  transpilePackages: ['@hkexpressairwayslimited/ui'],
  experimental: {
    optimizePackageImports: [
      '@hkexpressairwayslimited/ui',
      '@mui/x-data-grid',
      '@mui/x-date-pickers',
      '@mui/x-date-pickers-pro',
      '@hcaptcha/react-hcaptcha',
      'postcss',
      'tldts',
      'google-libphonenumber',
      'dayjs',
      '@mui/base',
    ],
    useLightningcss: true,
  },

  async headers() {
    return [
      {
        source: '/:all*(css|js|gif|svg|jpg|jpeg|png|woff|woff2)',
        locale: false,
        headers: [
          {
            key: 'Cache-Control',
            value: 'public, max-age=31536000',
          },
        ],
      },
      {
        source: '/:source/:path',
        locale: false,
        headers: [
          {
            key: 'Content-Disposition',
            value: 'inline',
          },
        ],
      },
    ];
  },

  generateBuildId: process.env.GIT_SHA
    ? async () => {
        // This could be anything, using the latest git hash
        return process.env.GIT_SHA;
      }
    : undefined,

  poweredByHeader: false,

  productionBrowserSourceMaps:
    process.env.NODE_ENV === 'production' && process.env.NEXTJS_PRODUCTION_SOURCE_MAPS === 'true'
      ? true
      : undefined,

  // By default, generated cache assets will be stored in memory (defaults to 50mb) and on disk. If you are hosting Next.js using a container orchestration platform like Kubernetes, each pod will have a copy of the cache. To prevent stale data from being shown since the cache is not shared between pods by default, you can configure the Next.js cache to provide a cache handler and disable in-memory caching.
  // cacheHandler: require.resolve('./cache-handler.js'),
  cacheMaxMemorySize: process.env.NODE_ENV === 'development' ? undefined : 0, // disable default in-memory caching

  webpack: (config, { isServer }) => {
    if (!isServer) {
      config.resolve.fallback.fs = false;
    }

    return config;
  },
};

if (process.env.NODE_ENV === 'production') {
  nextConfig.output = 'standalone';
}

module.exports = () => {
  // Run the base config through any configured plugins
  const config = Object.values(plugins).reduce((acc, plugin) => plugin(acc), nextConfig);

  if (typeof window === 'undefined') {
    const withBundleAnalyzer = require('@next/bundle-analyzer')({
      enabled: process.env.ANALYZE === 'true',
    });

    return withBundleAnalyzer(config);
  }
  return config;
};
