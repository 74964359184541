import {
  AppContext,
  Button,
  ButtonVariant,
  HomeIcon,
  Link,
  Section,
  SvgIconSize,
  Text,
  colors,
} from '@hkexpressairwayslimited/ui';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Field,
  Placeholder,
  useSitecoreContext,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useContext } from 'react';

type MainContentProps = {
  params: {
    enableBreadcrumbs: boolean;
  };
} & ComponentProps;

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const MainContent = ({ rendering, params }: MainContentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const { breadcrumbs } = useContext(AppContext);

  return (
    <Section>
      {params.enableBreadcrumbs && (
        <Breadcrumbs
          maxItems={4}
          separator={<Text color={colors.neutralBorderMain}>/</Text>}
          style={{ marginTop: 14, marginBottom: 24 }}
        >
          {breadcrumbs?.map((breadcrumb, index) =>
            index === 0 ? (
              <Link key={index} href={breadcrumb.href}>
                <Button noBorder variant={ButtonVariant.Icon} iconSize={SvgIconSize.Desktop}>
                  <HomeIcon />
                </Button>
              </Link>
            ) : (
              <Link key={index} href={breadcrumb.href}>
                {breadcrumb.title}
              </Link>
            )
          )}
          <Text>
            {((sitecoreContext.route?.fields?.pageTitle as Field<string>)?.value as string) || ''}
          </Text>
        </Breadcrumbs>
      )}
      <Placeholder name="main-content" rendering={rendering} />
    </Section>
  );
};

export default withDatasourceCheck()<MainContentProps>(MainContent);
